<template>
    <div class="notification-table-container px-0 mx-0 mb-2">
        <b-table
            v-if="items.length"
            responsive
            fixed
            no-local-sorting
            id="notification-table"
            class="table-list mb-0"
            :items="items"
            :fields="fields"
            :sort-by="queryParams.sortBy"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #cell(date)="{item}">{{ item.created | date }}</template>
            <template #cell(created_by)="{item}">
                {{ item.createdByName }}
            </template>
            <template #cell(number)="{item}">
                <custom-router-link
                    :to="{
                        name: 'ReserveDetails',
                        params: {orderId: item.orderHeaderId.toString()},
                    }"
                    >{{ item.orderHeaderId }}</custom-router-link
                >
            </template>
            <template #cell(patient_id)="{item}">
                <custom-router-link
                    :to="{
                        name: 'PatientDetails',
                        params: {patientId: item.patientId},
                    }"
                >
                    {{ item.ocosPatientId }}
                </custom-router-link>
            </template>
            <template #cell(status)="{item}">
                {{
                    getReservationOrderStatus(item.reservationStatus, {
                        isConsignment: isConsignment(item),
                    })
                }}
            </template>
            <template #cell(surgeon)="{item}">
                <custom-router-link
                    :to="{
                        name: 'SurgeonDetails',
                        params: {doctorId: item.surgeonId},
                    }"
                    :disabled="!(item.surgeonId > 0) || !hasSurgeonPermission"
                >
                    {{ item.contactPartyNumber }} - {{ item.surgeonName }}
                </custom-router-link>
            </template>
            <template #cell(actions)="{item}">
                <b-icon
                    icon="trash"
                    scale="1.5"
                    class="pointer"
                    @click="$emit('change-status', item)"
                />
            </template>
        </b-table>
        <section v-else class="text-center heavy text-gray-dark h4">
            <b-icon icon="bell" scale="2" class="my-4" />
            <p>{{ t('noNotification') }}</p>
        </section>
    </div>
</template>

<script>
import {getReservationOrderStatus} from '@/utilities/formatters';
import {InventorySource} from '@/constants/inventory';

export default {
    name: 'ReservationNotificationTable',
    components: {},
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            colWidths: {
                //based on a 1536px width resolution screen
                date: '100px',
                created_by: '150px',
                number: '100px',
                patient_id: '100px',
                status: '100px',
                surgeon: '250px',
                actions: '70px',
            },
        };
    },
    computed: {
        hasSurgeonPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.SURGEONS]: [
                    this.PERMISSIONS_VALUES.READ_ONLY,
                    this.PERMISSIONS_VALUES.READ_WRITE,
                    this.PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                ],
            });
        },
        fields() {
            return [
                {
                    key: 'date',
                    label: this.t('date'),
                    sortable: true,
                    tdClass: 'text-caption text-gray-dark white-space-pre',
                },
                {
                    key: 'created_by',
                    label: this.t('createdBy'),
                    tdClass: 'text-caption heavy',
                    sortable: true,
                },
                {
                    key: 'number',
                    label: this.t('number'),
                    sortable: true,
                },
                {
                    key: 'patient_id',
                    label: this.t('patientId'),
                    tdClass: 'text-caption heavy',
                    sortable: true,
                },
                {
                    key: 'status',
                    label: this.t('statusNormalCase'),
                    tdClass: 'text-caption heavy',
                    sortable: true,
                },
                {
                    key: 'surgeon',
                    label: this.t('surgeon'),
                    tdClass: 'text-caption heavy',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: this.t('actions'),
                    tdClass: 'text-caption heavy text-center',
                    sortable: false,
                },
            ];
        },
    },
    methods: {
        getReservationOrderStatus,
        isConsignment(item) {
            return item.lensSourceId == InventorySource.CONSIGNMENT;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .light-border-right {
    border-right: 1px solid $gray-light;
}

::v-deep .table-list {
    tr {
        height: 40px;
    }
}

.not-pointer {
    pointer-events: none;
}
</style>
